import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import { getStorage } from 'firebase/storage';

// Initialize Firebase
const firebaseApp = initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
});

// Initialize messaging
let messaging; // eslint-disable-line import/no-mutable-exports
try {
  messaging = getMessaging(firebaseApp);
} catch {
  messaging = {};
}

// Initialize storage
const storage = getStorage(firebaseApp);

export {
  firebaseApp,
  messaging,
  storage,
};
