import { createI18n } from 'vue-i18n';
import messagesEN from '@/assets/i18n/en/messages';
import messagesNL from '@/assets/i18n/nl/messages';
import messagesVLS from '@/assets/i18n/vls/messages';
import messagesFR from '@/assets/i18n/fr/messages';
import messagesDE from '@/assets/i18n/de/messages';

export const messages = {
  en: messagesEN,
  nl: messagesNL,
  vls: messagesVLS,
  fr: messagesFR,
  de: messagesDE,
};

export const defaultLanguage = 'nl';
const preferred = navigator.language.split('-')[0];
const locale = messages[preferred] !== undefined ? preferred : defaultLanguage;

const i18n = createI18n({
  locale,
  fallbackLocale: defaultLanguage,
  silentFallbackWarn: true,
  messages,
});

export const languages = [
  { locale: 'en', name: 'English' },
  { locale: 'nl', name: 'Nederlands' },
  { locale: 'vls', name: 'Flemish' },
  { locale: 'fr', name: 'Français' },
  { locale: 'de', name: 'Deutsch' },
];

export function changeLocale(setting) {
  i18n.global.locale = setting;
}

export default i18n;
