import { apolloClient, apolloCustomerClient } from '@/plugins/apollo';
import locationQuery from '@/graphql/appointments/location.gql';
import createAppointmentMutation from '@/graphql/appointments/create.gql';
import showQuery from '@/graphql/appointments/show.gql';

export default {
  /**
   * Location show.
   *
   * @param {Number} id
   * @param {Object} params
   *
   * @returns {Object}
   */
  async getLocation(id, params) {
    const response = await apolloClient.query({
      query: locationQuery,
      variables: {
        id,
        ...params,
      },
    });

    return response.data?.locations[0] || null;
  },

  /**
   * Create appointment.
   *
   * @param {Object} variables
   *
   * @returns {Object}
   */
  async create(variables = {}) {
    const response = await apolloClient.mutate({
      mutation: createAppointmentMutation,
      variables,
    });

    return response.data.createAnonymousAppointmentMutation;
  },
  /**
   * Get appointment.
   *
   * @param {Number} id
   *
   * @returns {Object}
   */
  async getAppointment(id) {
    const response = await apolloCustomerClient.query({
      query: showQuery,
      variables: {
        id,
      },
    });

    return response.data?.appointments[0] ?? null;
  },
};
