import {
  toastController,
  loadingController,
} from '@ionic/vue';
import i18n from '@/plugins/i18n';

export default {
  data() {
    return {
      error: null,
    };
  },
  methods: {
    /**
     * Handle request errors.
     *
     * @param {Function} callback
     * @param {Boolean} loader
     */
    async handleErrors(callback, loader = true) {
      let loading;

      if (loader) {
        loading = await loadingController.create({
          message: i18n.global.t('general.loading'),
        });
        await loading.present();
      }

      try {
        this.error = null;
        await callback();
      } catch (e) {
        this.error = e;
        const toast = await toastController.create({
          message: i18n.global.t('general.error', { message: e.toString() }),
          duration: 2000,
          color: 'danger',
        });
        await toast.present();
      } finally {
        if (loader) {
          await loading.dismiss();
        }
      }
    },
  },
};
