import { format, startOfWeek, endOfWeek } from 'date-fns';
import { apolloClient } from '@/plugins/apollo';
import listQuery from '@/graphql/locations/list.gql';
import showQuery from '@/graphql/locations/show.gql';
import store from '@/store';

export default {
  /**
   * Location list.
   *
   * @returns {Object}
   */
  async getList() {
    const response = await apolloClient.query({
      query: listQuery,
    });

    store.locations = response.data.locations;

    return store.locations;
  },

  /**
   * Location show.
   *
   * @param {Number} id
   *
   * @returns {Object}
   */
  async getLocation(id) {
    const options = { weekStartsOn: 1 };
    const date = new Date();
    const dateFormat = 'yyyy-MM-dd';
    const response = await apolloClient.query({
      query: showQuery,
      variables: {
        id,
        start: format(startOfWeek(date, options), dateFormat),
        end: format(endOfWeek(date, options), dateFormat),
      },
    });

    return response.data?.locations[0] || null;
  },
};
