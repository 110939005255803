<template>
  <ion-page>
    <ion-header>
      <ion-toolbar color="secondary">
        <ion-buttons slot="start">
          <ion-button @click="modalController.dismiss">
            <ion-icon
              slot="icon-only"
              :src="close"
              color="primary"
            />
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ $t('general.appointment') }}
          <ul class="stepper">
            <li />
            <li />
            <li />
          </ul>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-y="false">
      <div
        ref="content"
        class="content"
      >
        <ion-slides
          ref="slider"
          :pager="true"
          :options="slideOpts"
        >
          <ion-slide class="ion-padding ion-text-start">
            <ion-list
              class="ion-no-padding ion-margin-top-sm"
              lines="none"
            >
              <ion-radio-group v-model="appointment.vehicle">
                <ion-list-header class="ion-no-padding ion-margin-bottom-sm">
                  <ion-label class="ion-no-margin">
                    {{ $t('appointment.vehicle') }}
                  </ion-label>
                </ion-list-header>

                <ion-item-group v-if="vehicles.length">
                  <ion-item
                    v-for="vehicle in vehicles"
                    :key="`vehicle-${vehicle.id}`"
                    class="ion-margin-bottom-sm"
                  >
                    <ion-thumbnail
                      slot="start"
                      class="vehicle"
                    >
                      <car-logo :vehicle="vehicle" />
                    </ion-thumbnail>
                    <ion-label>
                      <h3>{{ vehicle.brand }} {{ vehicle.model }}</h3>
                      <h2>
                        {{ vehicle.identifiers
                          .find(i => i.type === 'license_plate')?.value ?? null }}
                      </h2>
                    </ion-label>
                    <ion-radio :value="vehicle" />
                  </ion-item>
                </ion-item-group>
                <ion-item-group v-else>
                  <ion-item
                    v-for="n in 2"
                    :key="`vehicle-skeleton-${n}`"
                    class="ion-margin-bottom-sm"
                  >
                    <ion-thumbnail
                      slot="start"
                      class="vehicle"
                    >
                      <img src="img/placeholder.jpg">
                    </ion-thumbnail>
                    <ion-label>
                      <h3>
                        <ion-skeleton-text
                          style="width: 30%;"
                          animated
                        />
                      </h3>
                      <h2>
                        <ion-skeleton-text
                          style="width: 70%;"
                          animated
                        />
                      </h2>
                    </ion-label>
                  </ion-item>
                </ion-item-group>
              </ion-radio-group>
            </ion-list>

            <ion-list
              class="ion-no-padding ion-margin-top"
              lines="none"
            >
              <ion-radio-group v-model="appointment.location">
                <ion-list-header class="ion-no-padding ion-margin-bottom-sm">
                  <ion-label class="ion-no-margin">
                    {{ $t('appointment.establishment') }}
                  </ion-label>
                </ion-list-header>

                <ion-item-group v-if="locations.length">
                  <ion-item
                    v-for="location in locations"
                    :key="`location-${location.id}`"
                    class="ion-margin-bottom-sm"
                  >
                    <ion-thumbnail slot="start">
                      <img
                        :alt="location.name"
                        :src="location.photo_url !== '' ? location.photo_url : 'img/location.jpg'"
                      >
                    </ion-thumbnail>
                    <ion-label>
                      <h3>{{ location.name }}</h3>
                      <h2>
                        {{ location.street }}
                        {{ location.house_number }}{{ location.house_number_suffix }}
                        {{ location.city }}
                      </h2>
                    </ion-label>
                    <ion-radio :value="location" />
                  </ion-item>
                </ion-item-group>
                <ion-item-group v-else>
                  <ion-item
                    v-for="n in 3"
                    :key="`location-skeleton-${n}`"
                    class="ion-margin-bottom-sm"
                  >
                    <ion-thumbnail slot="start">
                      <ion-skeleton-text animated />
                    </ion-thumbnail>
                    <ion-label>
                      <h3>
                        <ion-skeleton-text
                          style="width: 30%;"
                          animated
                        />
                      </h3>
                      <h2>
                        <ion-skeleton-text
                          style="width: 70%;"
                          animated
                        />
                      </h2>
                    </ion-label>
                  </ion-item>
                </ion-item-group>
              </ion-radio-group>
            </ion-list>

            <ion-list
              v-if="appointment.location?.specialties?.length > 1"
              class="ion-no-padding ion-margin-top"
              lines="none"
            >
              <ion-radio-group v-model="appointment.specialty">
                <ion-list-header class="ion-no-padding ion-margin-bottom-sm">
                  <ion-label class="ion-no-margin">
                    {{ $t('appointment.specialty') }}
                  </ion-label>
                </ion-list-header>

                <ion-item-group>
                  <ion-item
                    v-for="specialty in appointment.location.specialties"
                    :key="`specialty-${specialty.id}`"
                    class="ion-margin-bottom-sm"
                  >
                    <ion-thumbnail slot="start">
                      <img
                        :alt="specialty.name"
                        :src="specialty.image_url
                          ?? appointment.location.photo_url !== '' ? appointment.location.photo_url
                          : 'img/location.jpg'
                        "
                      >
                    </ion-thumbnail>
                    <ion-label>
                      <h3>{{ specialty.name }}</h3>
                    </ion-label>
                    <ion-radio :value="specialty" />
                  </ion-item>
                </ion-item-group>
              </ion-radio-group>
            </ion-list>

            <ion-button
              :disabled="!stepOne"
              expand="block"
              @click="next"
            >
              {{ $t('general.next') }}
            </ion-button>
          </ion-slide>

          <ion-slide class="ion-padding ion-text-start">
            <template v-if="activities.length">
              <h3 class="ion-no-margin ion-margin-top-sm">
                {{ $t('general.type') }} *
              </h3>
              <ion-select
                v-model="appointment.activity"
                :interface-options="{ header: $t('general.type') }"
                :placeholder="$t('general.choice')"
                class="text-sm ion-margin-top-sm"
              >
                <ion-select-option
                  v-for="activity in activities"
                  :key="`activity-${activity.id}`"
                  :value="activity.id"
                >
                  {{ activity.name }}
                </ion-select-option>
              </ion-select>
            </template>

            <template v-if="sub_activities.length">
              <h3 class="ion-no-margin ion-margin-top-lg">
                {{ $t('appointment.activities') }}
              </h3>
              <ion-card class="ion-no-margin ion-margin-top-sm">
                <ion-list class="ion-no-padding">
                  <ion-item
                    v-for="activity in sub_activities"
                    :key="`activity-${activity.id}`"
                    color="light"
                  >
                    <ion-label>
                      <h3>{{ activity.name }}</h3>
                    </ion-label>
                    <ion-checkbox
                      slot="start"
                      v-model="appointment.sub_activities[activity.id]"
                      color="primary"
                    />
                  </ion-item>
                </ion-list>
              </ion-card>
            </template>

            <h3 class="ion-no-margin ion-margin-top-lg">
              {{ $t('general.replacement_vehicle') }} *
            </h3>
            <ion-select
              v-model="appointment.replacement_vehicle"
              :interface-options="{ header: $t('general.replacement_vehicle') }"
              :placeholder="$t('general.choice')"
              class="text-sm ion-margin-top-sm"
            >
              <ion-select-option
                v-for="replacement_vehicle in appointment.specialty?.replacement_vehicles ?? []"
                :key="`replacement-vehicle-${replacement_vehicle.id}`"
                :value="replacement_vehicle.id"
              >
                {{ replacement_vehicle.name }}
              </ion-select-option>
            </ion-select>

            <h3 class="ion-no-margin ion-margin-top-lg">
              {{ $t('general.mileage') }}
            </h3>
            <ion-input
              v-model="appointment.mileage"
              type="number"
              class="ion-margin-top-sm"
            />

            <h3 class="ion-no-margin ion-margin-top-lg">
              {{ $t('general.remarks') }}
            </h3>
            <ion-textarea
              v-model="appointment.remarks"
              class="ion-margin-top-sm"
            />

            <ion-button
              :disabled="!stepTwo"
              expand="block"
              @click="next"
            >
              {{ $t('general.next') }}
            </ion-button>

            <ion-button
              fill="clear"
              expand="block"
              color="medium"
              class="ion-no-margin"
              @click="prev"
            >
              {{ $t('general.prev') }}
            </ion-button>
          </ion-slide>

          <ion-slide class="ion-padding ion-text-start">
            <h3 class="ion-no-margin ion-margin-top-sm">
              {{ $t('appointment.date') }}
            </h3>
            <ion-card class="ion-no-margin ion-margin-top-sm ion-no-padding">
              <date-picker
                v-model="appointment.date"
                is-expanded
                is-required
                :attributes="attributes"
                :first-day-of-week="2"
                :disabled-dates="{ start: null, end: null }"
                :available-dates="available_dates"
                @update:to-page="updateCalendar"
              />
            </ion-card>

            <h3 class="ion-no-margin ion-margin-top-lg">
              {{ $t('appointment.time') }}
            </h3>
            <ion-select
              v-model="appointment.time"
              :interface-options="{ header: $t('appointment.time') }"
              :placeholder="$t('general.choice')"
              class="text-sm ion-margin-top-sm"
            >
              <ion-select-option
                v-for="available_time in available_times"
                :key="`available-time-${available_time}`"
                :value="available_time"
              >
                {{ available_time }}
              </ion-select-option>
            </ion-select>

            <ion-button
              :disabled="!stepThree"
              expand="block"
              class="ion-margin-top-lg"
              @click="plan"
            >
              {{ $t('appointment.plan') }}
            </ion-button>

            <ion-button
              fill="clear"
              expand="block"
              color="medium"
              class="ion-no-margin"
              @click="prev"
            >
              {{ $t('general.prev') }}
            </ion-button>
          </ion-slide>
        </ion-slides>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonButtons,
  IonSlides,
  IonSlide,
  IonListHeader,
  IonItemGroup,
  IonRadio,
  IonRadioGroup,
  IonSelect,
  IonCheckbox,
  IonInput,
  IonTextarea,
  IonSelectOption,
  modalController,
} from '@ionic/vue';
import {
  close,
} from 'ionicons/icons';
import {
  format, endOfMonth, startOfDay, endOfDay,
} from 'date-fns';
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
import CarLogo from '@/components/CarLogo.vue';
import ErrorMixin from '@/mixins/ErrorMixin';
import SliderMixin from '@/mixins/SliderMixin';
import store from '@/store';
import UserService from '@/services/User';
import LocationsService from '@/services/Locations';
import AppointmentsService from '@/services/Appointments';
import LeadsService from '@/services/Leads';

const dateFormat = 'yyyy-MM-dd';

export default defineComponent({
  components: {
    IonButtons,
    IonSlides,
    IonSlide,
    IonListHeader,
    IonItemGroup,
    IonRadio,
    IonRadioGroup,
    IonSelect,
    IonCheckbox,
    IonInput,
    IonTextarea,
    IonSelectOption,
    CarLogo,
    DatePicker,
  },
  mixins: [ErrorMixin, SliderMixin],
  props: {
    vehicleId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup() {
    return {
      modalController,
      close,
    };
  },
  data() {
    return {
      slideOpts: {
        initialSlide: 0,
        allowTouchMove: false,
        pagination: {
          el: '.stepper',
          type: 'bullets',
          bulletActiveClass: 'active',
          bulletClass: 'noop',
          bulletElement: 'li',
        },
      },
      appointment: {
        vehicle: null,
        location: null,
        specialty: null,
        activity: null,
        sub_activities: [],
        replacement_vehicle: null,
        mileage: null,
        remarks: null,
        date: null,
        time: null,
      },
    };
  },
  computed: {
    organisation: () => store.organisation,
    customer: () => store.user.customer,
    vehicles: () => store.user.customer.vehicles,
    locations: () => store.locations,
    activities() {
      return this.appointment.specialty?.activities?.filter(((activity) => activity.type === 'main')) ?? [];
    },
    sub_activities() {
      return this.appointment.specialty?.activities?.filter(((activity) => activity.type === 'sub')) ?? [];
    },
    stepOne() {
      return this.appointment.vehicle && this.appointment.location && this.appointment.specialty;
    },
    stepTwo() {
      return this.appointment.activity && this.appointment.replacement_vehicle;
    },
    stepThree() {
      return this.appointment.date && this.appointment.time;
    },
    attributes() {
      return [
        {
          key: 'today',
          dot: true,
          dates: new Date(),
        },
        {
          bar: 'red',
          dates: this.appointment.specialty?.availabilities?.filter((a) => a.availability === 'unavailable').map((a) => new Date(a.date)) ?? [],
        },
        {
          bar: 'yellow',
          dates: this.appointment.specialty?.availabilities?.filter((a) => a.availability === 'partially').map((a) => new Date(a.date)) ?? [],
        },
        {
          bar: 'green',
          dates: this.appointment.specialty?.availabilities?.filter((a) => a.availability === 'available').map((a) => new Date(a.date)) ?? [],
        },
      ];
    },
    available_dates() {
      return this.appointment.specialty?.availabilities?.filter((a) => ['partially', 'available'].includes(a.availability)).map((a) => ({
        start: startOfDay(new Date(a.date)),
        end: endOfDay(new Date(a.date)),
      })) ?? [];
    },
    available_times() {
      return this.appointment.date ? this.appointment.specialty?.available_times ?? [] : [];
    },
  },
  watch: {
    'appointment.location': {
      handler(location, prev) {
        // Reset specialty when a new location is selected
        if (location?.id !== prev?.id) {
          this.appointment.specialty = null;
        }

        if (location) {
          if (location.specialties?.length === 1) {
            [this.appointment.specialty] = location.specialties;
          }
          if (this.customer.default_location_id === location.id
            && this.customer.default_specialty_id) {
            this.appointment.specialty = location.specialties?.find(
              (s) => +s.id === +this.customer.default_specialty_id,
            );
          }
        }
      },
    },
    'appointment.date': {
      async handler() {
        this.appointment.location = await this.getLocation();
      },
    },
    'appointment.activity': {
      async handler() {
        this.appointment.location = await this.getLocation();
      },
    },
    'appointment.replacement_vehicle': {
      async handler() {
        this.appointment.location = await this.getLocation();
      },
    },
  },
  async created() {
    await Promise.all([
      UserService.getProfile(),
      LocationsService.getList(),
    ]);

    // Set default vehicle if only one.
    if (this.vehicles.length === 1) {
      [this.appointment.vehicle] = this.vehicles;
    }
    if (this.vehicleId) {
      this.appointment.vehicle = this.vehicles.find((v) => v.id === +this.vehicleId);
    }

    // Set default location if only one
    if (this.locations.length === 1) {
      [this.appointment.location] = this.locations;
    }
    if (this.customer.default_location_id) {
      this.appointment.location = this.locations.find(
        (l) => +l.id === +this.customer.default_location_id,
      );
    }
  },
  methods: {
    /**
     * Go to next slide.
     */
    async next() {
      await this.$refs.slider.$el.slideNext();
      this.$refs.content.scrollTop = 0;
    },
    /**
     * Go to prev slide.
     */
    async prev() {
      await this.$refs.slider.$el.slidePrev();
      this.$refs.content.scrollTop = 0;
    },
    /**
     * Get location according to appointment.
     *
     * @param {Object} params
     *
     * @return {Promise<Object>}
     */
    getLocation(params = {}) {
      if (!this.appointment.location) {
        return null;
      }

      const date = this.appointment.date ?? new Date();

      return AppointmentsService.getLocation(this.appointment.location.id, {
        date: format(date, dateFormat),
        start: format(new Date(), dateFormat),
        end: format(endOfMonth(date), dateFormat),
        activity: this.appointment.activity ?? '',
        replacement_vehicle: this.appointment.replacement_vehicle,
        ...params,
      });
    },
    /**
     * Update calendar.
     *
     * @param {Event} e
     */
    async updateCalendar(e) {
      this.appointment.location = await this.getLocation({
        start: format(new Date(), dateFormat),
        end: format(endOfMonth(new Date(e.year, e.month - 1, 1)), dateFormat),
      });
    },
    /**
     * Plan appointment.
     */
    async plan() {
      let result;
      await this.handleErrors(async () => {
        if (this.organisation.uses_v2) {
          result = await LeadsService.create({
            lead: {
              lead_type: 'appointment_request',
              state: 'open',
              customerData: Object.keys(this.customer.data)
                .map((key) => ({ key, value: this.customer.data[key] })),
              leadData: [
                {
                  key: 'activity',
                  value: this.appointment.activity,
                },
                {
                  key: 'replacement_vehicle',
                  value: this.appointment.replacement_vehicle,
                },
                {
                  key: 'license_plate',
                  value: this.appointment.vehicle.identifiers.find((i) => i.type === 'license_plate')?.value ?? null,
                },
                {
                  key: 'mileage',
                  value: this.appointment.mileage ?? '0',
                },
                {
                  key: 'remarks',
                  value: this.appointment.remarks ?? '',
                },
                {
                  key: 'specialty_id',
                  value: this.appointment.specialty.id,
                },
                {
                  key: 'date_iso8601',
                  value: `${format(this.appointment.date, 'yyyy-MM-dd')}T${this.appointment.time}:00.000Z`,
                },
              ],
              lead_provider_id: store.leadProviderId,
            },
          });
        } else {
          result = await AppointmentsService.create({
            email: store.user.email,
            first_name: store.user.customer.data.name,
            last_name: store.user.customer.data.surname,
            activity: this.appointment.activity,
            replacement_vehicle: this.appointment.replacement_vehicle,
            license_plate: this.appointment.vehicle.identifiers.find((i) => i.type === 'license_plate')?.value ?? null,
            date: `${format(this.appointment.date, 'yyyy-MM-dd')} ${this.appointment.time}`,
            mileage: +this.appointment.mileage,
            phone: store.user.customer.data.phone_number,
            remarks: this.appointment.remarks,
            specialty_id: this.appointment.specialty.id,
            location_id: this.appointment.location.id,
            sub_activities: Object.keys(this.appointment.sub_activities).map((sa) => `${sa}`),
            vehicle_brand: this.appointment.vehicle.brand,
            vehicle_model: this.appointment.vehicle.model,
            vehicle_buildyear: this.appointment.vehicle.buildyear,
          });
        }

        if (result) {
          await modalController.dismiss();

          this.$gtag.event('afspraak_gemaakt');
        }
      });
    },
  },
});
</script>

<style>
.stepper {
  margin-top: 6px !important;

  li.noop {
    opacity: 0.25;
    background: var(--ion-color-light) !important;

    &.active {
      opacity: 1;
      background: var(--ion-color-primary) !important;
    }
  }
}

.ios .stepper {
  justify-content: center;
}

.vc-day-content.is-disabled {
  pointer-events: none;
}
</style>

<style scoped>
.swiper-slide {
  display: block;

  img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }
}

ion-list-header {
  min-height: 0;
  font-size: 16px;
  font-weight: 500;

  --background: var(--ion-color-step-100);
}

ion-list {
  background: var(--ion-color-step-100);
}

ion-radio-group {
  ion-item {
    border: 1px solid rgb(0 0 0 / 10%);
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    font-size: 16px;

    --background: var(--ion-color-light);
    --padding-start: 12px;

    &.item-radio-checked {
      border-color: var(--ion-color-primary);
    }

    ion-radio {
      opacity: 0;
    }

    ion-thumbnail {
      width: 90px;
      margin: 0 16px 0 -20px;

      &.vehicle {
        width: 81px;
        height: 80px;
        margin: 0 20px 0 -12px;
        padding: 10px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    ion-label {
      h3 {
        font-size: 14px;
        font-weight: 500;
      }

      h2 {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
      }
    }
  }
}

ion-checkbox {
  margin-right: 12px;

  --background: white;
}

.vc-container {
  border: none;
  box-shadow: none;
}
</style>
