import Tabs from '@/views/Tabs.vue';

export default [
  {
    name: 'login',
    path: '/login',
    meta: {
      auth: false,
    },
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/',
    meta: {
      auth: true,
    },
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/start',
      },
      {
        name: 'start',
        path: '/start',
        component: () => import('@/views/Start.vue'),
      },
      {
        name: 'start-appointment-detail',
        path: '/start/afspraak/:id',
        component: () => import('@/views/Appointment.vue'),
      },
      {
        name: 'messages',
        path: '/berichten',
        component: () => import('@/views/Messages.vue'),
      },
      {
        name: 'notification',
        path: '/berichten/:id',
        component: () => import('@/views/Notification.vue'),
      },
      {
        name: 'contact',
        path: '/contact',
        component: () => import('@/views/Contact.vue'),
      },
      {
        name: 'contact-establishment',
        path: '/contact/vestiging/:id',
        component: () => import('@/views/Establishment.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
      },
      {
        name: 'occasions',
        path: '/aanbod',
        component: () => import('@/views/Occasions.vue'),
      },
      {
        name: 'occasions-detail',
        path: '/aanbod/:id',
        component: () => import('@/views/Occasion.vue'),
      },
      {
        name: 'carflow',
        path: '/occasions',
        component: () => import('@/views/Carflow.vue'),
      },
      {
        name: 'carflow-details',
        path: '/occasions/details',
        component: () => import('@/views/CarflowDetails.vue'),
      },
      {
        name: 'profile',
        path: '/profiel',
        component: () => import('@/views/Profile.vue'),
      },
      {
        name: 'profile-car',
        path: '/profiel/auto/:id',
        component: () => import('@/views/Car.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
      },
      {
        name: 'more',
        path: '/meer',
        component: () => import('@/views/More.vue'),
      },
      {
        name: 'more-occasions',
        path: '/meer/aanbod',
        component: () => import('@/views/Occasions.vue'),
      },
      {
        name: 'more-occasions-detail',
        path: '/meer/aanbod/:id',
        component: () => import('@/views/Occasion.vue'),
      },
      {
        name: 'more-carflow',
        path: '/meer/occasions',
        component: () => import('@/views/Carflow.vue'),
      },
      {
        name: 'more-carflow-details',
        path: '/meer/occasions/details',
        component: () => import('@/views/CarflowDetails.vue'),
      },
      {
        name: 'more-news',
        path: '/meer/nieuws',
        component: () => import('@/views/News.vue'),
      },
      {
        name: 'more-news-detail',
        path: '/meer/nieuws/:id',
        component: () => import('@/views/NewsItem.vue'),
      },
      {
        name: 'more-contact',
        path: '/meer/contact',
        component: () => import('@/views/Contact.vue'),
      },
      {
        name: 'more-malfunctions',
        path: '/meer/storingen',
        component: () => import('@/views/Malfunctions.vue'),
      },
      {
        name: 'more-claim-form',
        path: '/meer/schadeformulier',
        component: () => import('@/views/ClaimForm.vue'),
      },
      {
        name: 'more-reviews',
        path: '/meer/klantbeoordelingen',
        component: () => import('@/views/Reviews.vue'),
      },
      {
        name: 'more-profile',
        path: '/meer/profiel',
        component: () => import('@/views/Profile.vue'),
      },
      {
        name: 'more-profile-car',
        path: '/meer/profiel/auto/:id',
        component: () => import('@/views/Car.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
      },
    ],
  },
];
