<template>
  <ion-page>
    <ion-tabs @ionTabsWillChange="selectedTab = $event.tab">
      <ion-router-outlet />
      <ion-tab-bar
        slot="bottom"
        color="light"
      >
        <ion-tab-button
          tab="start"
          href="/start"
        >
          <ion-icon :icon="selectedTab === 'start' ? speedometer : speedometerOutline" />
          <ion-label>{{ $t('start.title') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button
          tab="messages"
          href="/berichten"
        >
          <ion-icon :icon="selectedTab === 'messages' ? chatbubbles : chatbubblesOutline" />
          <ion-label>{{ $t('messages.title') }}</ion-label>
          <ion-badge
            v-if="messages"
            color="danger"
          >
            {{ messages }}
          </ion-badge>
        </ion-tab-button>
        <ion-tab-button
          v-if="config.pwa_menu_2 === 'contact'"
          tab="contact"
          href="/contact"
        >
          <ion-icon :icon="selectedTab === 'contact' ? call : callOutline" />
          <ion-label>{{ $t('contact.title') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button
          v-if="organisation.occasion_provider === 'api' && config.pwa_menu_2 === 'occasions'"
          tab="occasions"
          href="/aanbod"
        >
          <ion-icon :icon="selectedTab === 'occasions' ? carSport : carSportOutline" />
          <ion-label>{{ $t('occasions.title') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button
          v-if="organisation.occasion_provider === 'carflow' && config.pwa_menu_2 === 'occasions'"
          tab="occasions"
          href="/occasions"
        >
          <ion-icon :icon="selectedTab === 'occasions' ? carSport : carSportOutline" />
          <ion-label>{{ $t('occasions.title') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button
          v-if="config.pwa_menu_2 === 'profile'"
          tab="profile"
          href="/profiel"
        >
          <ion-icon :icon="selectedTab === 'profile' ? personCircle : personCircleOutline" />
          <ion-label>{{ $t('profile.title') }}</ion-label>
        </ion-tab-button>
        <ion-button
          v-if="config.pwa_menu_2 === 'appointment'"
          size="small"
          fill="clear"
          color="medium"
          class="ion-no-margin ion-no-padding"
          @click="openAppointment"
        >
          <div>
            <ion-icon :icon="calendarOutline" />
            {{ $t('appointment.title') }}
          </div>
        </ion-button>
        <ion-tab-button
          v-if="config.pwa_menu_3 === 'contact'"
          tab="contact"
          href="/contact"
        >
          <ion-icon :icon="selectedTab === 'contact' ? call : callOutline" />
          <ion-label>{{ $t('contact.title') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button
          v-if="organisation.occasion_provider === 'api' && config.pwa_menu_3 === 'occasions'"
          tab="occasions"
          href="/aanbod"
        >
          <ion-icon :icon="selectedTab === 'occasions' ? carSport : carSportOutline" />
          <ion-label>{{ $t('occasions.title') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button
          v-if="organisation.occasion_provider === 'carflow' && config.pwa_menu_3 === 'occasions'"
          tab="occasions"
          href="/occasions"
        >
          <ion-icon :icon="selectedTab === 'occasions' ? carSport : carSportOutline" />
          <ion-label>{{ $t('occasions.title') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button
          v-if="config.pwa_menu_3 === 'profile'"
          tab="profile"
          href="/profiel"
        >
          <ion-icon :icon="selectedTab === 'profile' ? personCircle : personCircleOutline" />
          <ion-label>{{ $t('profile.title') }}</ion-label>
        </ion-tab-button>
        <ion-button
          v-if="config.pwa_menu_3 === 'appointment'"
          size="small"
          fill="clear"
          color="medium"
          class="ion-no-margin ion-no-padding"
          @click="openAppointment"
        >
          <div>
            <ion-icon :icon="calendarOutline" />
            {{ $t('appointment.title') }}
          </div>
        </ion-button>
        <ion-tab-button
          tab="more"
          href="/meer"
        >
          <ion-icon :icon="selectedTab === 'more' ? menu : menuOutline" />
          <ion-label>{{ $t('more.title') }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonBadge, modalController,
} from '@ionic/vue';
import {
  speedometer,
  speedometerOutline,
  chatbubbles,
  chatbubblesOutline,
  calendarOutline,
  call,
  callOutline,
  menu,
  menuOutline,
  carSport,
  carSportOutline,
  personCircle,
  personCircleOutline,
} from 'ionicons/icons';
import Appointment from '@/components/Appointment.vue';
import UserService from '@/services/User';
import StartService from '@/services/Start';
import store from '@/store';

export default defineComponent({
  components: {
    IonRouterOutlet,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonBadge,
  },
  setup() {
    return {
      speedometer,
      speedometerOutline,
      chatbubbles,
      chatbubblesOutline,
      calendarOutline,
      call,
      callOutline,
      menu,
      menuOutline,
      carSport,
      carSportOutline,
      personCircle,
      personCircleOutline,
    };
  },
  data() {
    return {
      selectedTab: 'start',
    };
  },
  computed: {
    config: () => store.config,
    organisation: () => store.organisation,
    messages: () => (store.messages ? store.messages.length : 0),
  },
  watch: {
    /* c8 ignore start */
    selectedTab(tab) {
      if (tab === 'start') {
        StartService.getList();
      }
    },
    /* c8 ignore stop */
  },
  async created() {
    await Promise.all([
      UserService.getProfile(),
      UserService.registerDevice(),
      StartService.getUnreadMessages(),
    ]);
  },
  methods: {
    /**
     * Open appointment.
     */
    async openAppointment() {
      const modal = await modalController.create({
        component: Appointment,
        showBackdrop: false,
      });
      await modal.present();
      this.$gtag.screenview({
        screen_name: this.$t('appointment.title'),
      });
    },
  },
});
</script>
