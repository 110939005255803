import { Drivers, Storage } from '@ionic/storage/dist/esm';

export default {
  /**
   * Get auth host.
   *
   * @returns {String}
   */
  getHost() {
    const params = new URLSearchParams(window.location.search);
    const domain = process.env.VUE_APP_API_DOMAIN?.length ? process.env.VUE_APP_API_DOMAIN : null;
    return params.get('domain') ?? domain ?? window.location.hostname;
  },
  /**
   * Get token.
   *
   * @returns {String}
   */
  async getToken() {
    const storage = await this.getStorage();
    const token = await storage.get('token');

    return token;
  },
  /**
   * Set token.
   *
   * @param {String} token
   *
   * @returns {Promise}
   */
  async setToken(token) {
    const storage = await this.getStorage();

    return storage.set('token', token);
  },
  /**
   * Clear storage.
   *
   * @return {Promise}
   */
  async clearStorage() {
    const storage = await this.getStorage();

    return storage.clear();
  },
  /**
   * Get storage.
   *
   * @returns {Promise}
   */
  async getStorage() {
    const store = new Storage({
      name: this.getHost(),
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    });
    const storage = await store.create();

    return storage;
  },
};
