import { apolloClient } from '@/plugins/apollo';
import createLeadMutation from '@/graphql/leads/create.gql';
import createLeadProviderMutation from '@/graphql/leads/createProvider.gql';

export default {
  /**
   * Create lead.
   *
   * @param {Object} variables
   *
   * @returns {Boolean}
   */
  async create(variables = {}) {
    const response = await apolloClient.mutate({
      mutation: createLeadMutation,
      variables,
    });

    return response.data.createLead;
  },
  /**
   * Create lead provider.
   *
   * @param {Object} variables
   *
   * @returns {Boolean}
   */
  async createProvider(variables = {}) {
    const response = await apolloClient.mutate({
      mutation: createLeadProviderMutation,
      variables,
    });

    return response.data.createLeadProvider;
  },
};
