import { createRouter, createWebHistory } from '@ionic/vue-router';
import { Capacitor } from '@capacitor/core';
import { trackRouter } from 'vue-gtag-next';
import routes from '@/routes';
import AuthService from '@/services/Auth';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (from.query.domain && !to.query.domain) {
    next({ ...to, query: from.query });
    return;
  }

  if (to.meta.auth && !await AuthService.getToken() && Capacitor.getPlatform() !== 'ios') {
    next({ name: 'login', query: to.query });
    return;
  }

  next();
});

trackRouter(router);

export default router;
