import { format } from 'date-fns';
import { apolloCustomerClient } from '@/plugins/apollo';
import listQuery from '@/graphql/start/index.gql';
import chatQuery from '@/graphql/start/chat.gql';
import store from '@/store';

export default {
  /**
   * Get list.
   *
   * @returns {Object}
   */
  async getList() {
    const response = await apolloCustomerClient.query({
      query: listQuery,
      variables: {
        date: format(new Date(), 'yyyy-MM-dd HH:ii'),
      },
      fetchPolicy: 'network-only',
    });

    Object.assign(store.start, {
      messages: response.data.chat?.messages?.filter((message) => message.user) ?? [],
      appointments: response.data.appointments,
      notifications: response.data.notifications,
    });

    return store.start;
  },
  /**
   * Get unread messages.
   *
   * @returns {Object}
   */
  async getUnreadMessages() {
    const response = await apolloCustomerClient.query({
      query: chatQuery,
      variables: {
        read: false,
      },
      fetchPolicy: 'network-only',
    });

    const messages = response.data.chat?.messages?.filter((message) => message.user) ?? [];
    store.messages = messages;

    return messages;
  },
};
